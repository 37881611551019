<template>
  <div class="main-content">
    <avue-crud
      ref="crud"
      :data="tableData"
      :option="option"
      :table-loading="showLoading"
      :page.sync="page"
      @on-load="onLoad"
    >
      <template #header>
        <div>
          <div class="flex flex-wrap align-items marginBottom20">
            <div class="flex align-items marginRight10 marginBottom10">
              <span class="span marginRight10 width80 textAlignRight"
                >会员编号 :
              </span>
              <div class="width300">
                <el-input
                  v-model="searchData.code"
                  placeholder="请输入"
                  clearable
                  maxlength="30"
                ></el-input>
              </div>
            </div>
            <div class="flex align-items marginRight10 marginBottom10">
              <span class="span marginRight10 width80 textAlignRight"
                >会员姓名 :
              </span>
              <div class="width300">
                <el-input
                  v-model="searchData.username"
                  placeholder="请输入"
                  clearable
                  maxlength="20"
                ></el-input>
              </div>
            </div>
            <div class="flex align-items marginRight10 marginBottom10">
              <span class="span marginRight10">所属公司 : </span>
              <div class="width300">
                <el-select v-model="searchData.factoryId" clearable>
                  <el-option
                    v-for="item in classifyList"
                    :key="item.id"
                    :value="item.id"
                    :label="item.name"
                  ></el-option>
                </el-select>
              </div>
            </div>
            <div class="flex align-items marginRight10 marginBottom10">
              <span class="span marginRight10 width80 textAlignRight"
                >手机号码 :
              </span>
              <div class="width300">
                <el-input
                  v-model="searchData.phone"
                  placeholder="请输入"
                  clearable
                  maxlength="11"
                ></el-input>
              </div>
            </div>
            <div class="flex align-items marginRight10 marginBottom10">
              <span class="span width80 marginRight10 textAlignRight"
                >状态 :
              </span>
              <div class="width300">
                <el-select v-model="searchData.state" clearable>
                  <el-option
                    v-for="item in ODisplayStatus"
                    :key="item.value"
                    :value="item.value"
                    :label="item.label"
                  ></el-option>
                </el-select>
              </div>
            </div>

            <div class="marginLeft10 marginBottom10">
              <el-button size="medium" type="primary" @click="handleSearch"
                >搜索</el-button
              >
            </div>
            <div class="marginLeft10 marginRight10 marginBottom10">
              <el-button size="medium" type="info" @click="handleReset"
                >重置</el-button
              >
            </div>
            <div class="flex align-items marginRight10 marginBottom10">
              <el-button
                size="medium"
                style="
                  background: #f59a23;
                  color: #ffffff;
                  border-color: #f59a23;
                "
                @click="handleImport"
                >导入</el-button
              >
            </div>
            <div class="flex align-items marginRight10 marginBottom10">
              <el-button size="medium" type="primary" @click="handleCreate"
                >新增</el-button
              >
            </div>
          </div>
        </div>
      </template>
      <template slot="avatarUrl" slot-scope="{ row }">
        <el-image
          style="width: 50px; height: 50px; border-radius: 50%"
          :src="row.avatarUrl || userImg"
          :preview-src-list="[row.avatarUrl || userImg]"
        >
        </el-image>
      </template>
      <template slot="state" slot-scope="{ row }">
        <el-switch
          :value="row.state"
          :inactive-value="2"
          :active-value="1"
          @change="handleSwitch($event, row.id)"
        ></el-switch>
      </template>

      <template slot="menu" slot-scope="{ row }">
        <el-button type="text" size="mini" @click="handleEdit('view', row)"
          >详情</el-button
        >
        <el-button type="text" size="mini" @click="handleEdit('update', row)"
          >编辑</el-button
        >
        <el-button type="text" size="mini" @click="handleResetPwd(row)"
          >重置密码</el-button
        >
        <!-- <el-button type="text" size="mini" @click="openForm('create')"
          >发放徽章</el-button
        > -->
        <el-button
          type="text"
          size="mini"
          @click="handleDelete(row)"
          style="color: #ff001f !important"
          >删除</el-button
        >
      </template>
    </avue-crud>
    <el-dialog
      v-if="dialogVisible"
      :title="`${isDetails ? '详情' : form.id ? '修改会员' : '新增会员'}`"
      :visible.sync="dialogVisible"
      width="40%"
      :before-close="handleClose"
      :close-on-click-modal="false"
    >
      <avue-form ref="form" v-model="form" :option="formOption">
        <template slot="avatarUrl">
          <FormImgUpload
            :url="form.avatarUrl"
            @upload="upload"
            :limit="1"
            :maxSize="20"
            :accept="'.png,.jpg,.jpeg,.svg,.tiff'"
            :multiple="true"
            :disabled="isDetails"
          />
          <div style="color: #c0c1c3">
            建议尺寸：78px*78px，图片格式：.png,.jpg,.jpeg,.svg,.tiff
          </div>
        </template>
        <template slot="username">
          <el-input
            v-model="form.username"
            maxlength="20"
            placeholder="请输入"
            onkeyup="this.value=this.value"
            @blur="salaryDianChange($event, 'username')"
            type="text"
            clearable
            :disabled="isDetails"
          />
          <!-- onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/)" -->
        </template>
        <template slot="phone">
          <el-input
            v-model="form.phone"
            maxlength="11"
            placeholder="请输入"
            onkeyup="this.value=this.value"
            @blur="salaryDianChange($event, 'phone')"
            type="text"
            clearable
            :disabled="isDetails"
          />
        </template>
        <template slot="factoryId">
          <el-select
            v-model="form.factoryId"
            clearable
            style="width: 400px"
            :disabled="isDetails"
          >
            <el-option
              v-for="item in classifyList"
              :key="item.id"
              :value="item.id"
              :label="item.name"
            ></el-option>
          </el-select>
        </template>
        <template slot="post">
          <el-input
            v-model="form.post"
            maxlength="20"
            placeholder="请输入"
            onkeyup="this.value=this.value"
            @blur="salaryDianChange($event, 'post')"
            type="text"
            clearable
            :disabled="isDetails"
          />
        </template>

        <template slot="state">
          <el-switch
            v-if="!isDetails"
            :disabled="isDetails"
            v-model="form.state"
            :inactive-value="2"
            :active-value="1"
          ></el-switch>
          <div v-else>
            {{ form.state == 1 ? "启用中" : form.state == 2 ? "停用中" : "" }}
          </div>
        </template>

        <template slot="menuForm">
          <el-button @click="handleClose">
            {{ isDetails ? "关闭" : "取消" }}</el-button
          >
          <el-button
            type="primary"
            v-if="!isDetails"
            @click="submit(form)"
            :loading="showLoadingForm"
            >确定</el-button
          >
        </template>
      </avue-form>
    </el-dialog>
    <Import
      v-if="importShow"
      :show="importShow"
      :action="importAction"
      :downloadLink="importDownloadLink"
      :problemLink="importProblem"
      @close="importClose"
    />
    <!-- <UserForm
      ref="formRef"
      @success="handleSearch"
      :badgeClassifyList="badgeClassifyList"
    /> -->
  </div>
</template>

<script>
import {
  manageFactoryList,
  memberMemberList,
  memberManageAdd,
  memberManageUpdate,
  memberManageDelete,
  memberManageView,
  memberManagePassword,
  memberManageState,
} from "@/api/user/manage";
import dayjs from "dayjs";
import FormImgUpload from "@/components/formComponents/formImgUpload";
import { emailRegex, mobilePhoneRule, passwordRule } from "@/utils/formRules";
import UserForm from "./UserForm.vue";
import Import from "@/components/import";
import { MDisplayStatus, ODisplayStatus } from "@/enums/beCurrent/EBeCurrent";

export default {
  name: "index",
  components: {
    FormImgUpload,
    UserForm,
    Import,
  },
  data() {
    return {
      ODisplayStatus: ODisplayStatus,
      searchData: {
        createTime: "",
      },
      page: {
        total: 0,
        currentPage: 1,
      },
      tableData: [],
      showLoading: false,
      showLoadingForm: false,
      dialogVisible: false,
      selectedRowKeys: [], //表格勾选中的数据
      option: {
        title: "",
        titleSize: "h3",
        card: false,
        border: true,
        header: false,
        stripe: false, // 显示斑马纹
        showHeader: true, // 显示表头
        index: true, // 显示索引
        indexLabel: "序号", // 索引文字
        size: "small",
        page: true, // 显示分页
        align: "center",
        menuAlign: "center",
        menuType: "text",
        editBtn: false,
        delBtn: false,
        selection: false,
        reserveSelection: true,
        selectionFixed: true,
        tip: false,
        column: [
          {
            label: "会员编号",
            prop: "code",
          },

          {
            label: "头像",
            prop: "avatarUrl",
          },
          {
            label: "会员姓名",
            prop: "username",
          },
          {
            label: "手机号码",
            prop: "phone",
          },
          {
            label: "所属公司",
            prop: "factoryName",
          },

          {
            label: "职位",
            prop: "post",
          },

          {
            label: "创建时间",
            prop: "createTime",
          },
          {
            label: "状态",
            prop: "state",
          },
        ],
      },

      dialogVisible: false,
      form: {
        image: [],
        state: 1,
      },
      formOption: {
        submitBtn: false,
        submitText: "确定",
        emptyBtn: false,
        labelWidth: "120",
        row: true,
        column: [
          {
            label: "头像",
            prop: "avatarUrl",
            type: "text",
            maxlength: 50,
            span: 24,
            rules: [
              {
                required: true,
                message: "请上传",
                trigger: "blur",
              },
              {
                validator: (rule, value, callback) => {
                  if (value.length === 0) {
                    callback(new Error("请上传"));
                  } else {
                    callback();
                  }
                },
              },
            ],
          },
          {
            label: "会员名称",
            prop: "username",
            type: "text",
            maxlength: 20,
            placeholder: "请输入",
            span: 14,
            rules: [
              {
                required: true,
                message: "请输入",
                trigger: "blur",
              },
            ],
          },
          {
            label: "职位",
            prop: "post",
            type: "text",
            maxlength: 200,
            placeholder: "请输入",
            span: 14,
            rules: [
              {
                required: true,
                message: "请输入",
                trigger: "blur",
              },
            ],
          },
          {
            label: "所属公司",
            prop: "factoryId",
            type: "text",
            maxlength: 200,
            placeholder: "请输入",
            span: 14,
            rules: [
              {
                required: true,
                message: "请输入",
                trigger: "blur",
              },
            ],
          },
          {
            label: "手机号",
            prop: "phone",
            type: "text",
            maxlength: 11,
            placeholder: "请输入",
            span: 14,
            rules: [
              {
                required: true,
                message: "请输入",
                trigger: "blur",
              },
              ...mobilePhoneRule,
            ],
          },
          {
            label: "密码",
            prop: "password",
            type: "password",
            maxlength: 20,
            placeholder: "请输入",
            span: 14,
            rules: [
              {
                required: true,
                message: "请输入",
                trigger: "blur",
              },
              ...passwordRule,
            ],
          },
          {
            label: "状态",
            prop: "state",
            type: "text",
            maxlength: 100,
            span: 24,
            rules: [
              {
                required: true,
                message: "",
                trigger: "blur",
              },
            ],
          },
        ],
      },
      viewShow: false, //是否是详情
      importShow: false, //导入弹窗
      importAction: "/api/web/member/manage/template/import",
      importProblem: "/web/member/manage/template/error/export",
      importDownloadLink: "/web/member/manage/template/export",
      classifyList: [], //所属公司分类
      userImg:
        "https://l-xpert.obs.cn-southwest-2.myhuaweicloud.com/backstage/d3c7f081-9949-e88f-9928-09cd3a0b74d6.jpg", //默认头像
    };
  },
  created() {
    this.getClassifyList();
  },
  methods: {
    onLoad() {
      this.showLoading = true;
      const searchData = {
        ...this.searchData,
      };
      if (this.searchData.createTime) {
        searchData.visitTimeStart = dayjs(this.searchData.createTime[0]).format(
          "YYYY-MM-DD 00:00:00"
        );
        searchData.visitTimeEnd = dayjs(this.searchData.createTime[1]).format(
          "YYYY-MM-DD 23:59:59"
        );
      }
      delete searchData.createTime;
      memberMemberList({
        page: this.page.currentPage,
        size: this.page.pageSize,
        ...searchData,
      }).then((res) => {
        this.showLoading = false;
        if (res.code === 200) {
          this.tableData = res.data.records;
          this.page.total = res.data.total;
        }
      });
      this.showLoading = false;
    },

    // 查询
    handleSearch() {
      // console.log('查询')
      this.page.currentPage = 1;
      this.onLoad();
    },
    // 重置
    handleReset() {
      // console.log('重置')
      this.searchData = {
        state: "",
        name: "",
        createTime: "",
      };
      this.page.currentPage = 1;
      this.onLoad();
    },
    // 关闭
    handleClose() {
      this.dialogVisible = false;
      setTimeout(() => {
        this.resetForm();
      }, 500);
    },
    // 删除
    handleDelete(row) {
      this.$confirm("确定删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        memberManageDelete(row.id).then((res) => {
          if (res.code === 200) {
            this.$message.success("删除成功!");
            this.onLoad();
          }
        });
      });
    },
    // 情况表单
    resetForm() {
      this.form = {
        id: "",
        type: "",
      };
    },

    //详情
    handleView(row) {
      this.$router.push({
        path: "/member/manage/details",
        query: {
          id: row.id,
        },
      });
    },
    // 提交
    submit(form) {
      this.$refs.form.$refs.form.validate((valid, done) => {
        if (valid) {
          if (this.showLoadingForm) {
            return;
          }
          this.showLoadingForm = true;
          // return
          const newData = {
            ...form,
            avatarUrl: form.avatarUrl[0],
          };
          if (form.id) {
            memberManageUpdate(newData)
              .then((res) => {
                if (res.code === 200) {
                  this.$message.success("操作成功");
                  this.dialogVisible = false;
                  setTimeout(() => {
                    this.resetForm();
                  }, 500);

                  this.onLoad();
                }
                this.showLoadingForm = false;
              })
              .catch(() => (this.showLoadingForm = false));
          } else {
            memberManageAdd(newData)
              .then((res) => {
                if (res.code === 200) {
                  this.$message.success("操作成功");
                  this.dialogVisible = false;
                  setTimeout(() => {
                    this.resetForm();
                  }, 500);
                  this.onLoad();
                }
                this.showLoadingForm = false;
              })
              .catch(() => (this.showLoadingForm = false));
          }
          // this.form.type = 1;
          // done()
        }
      });
    },
    //输入
    salaryDianChange(e, t) {
      const value = e.target.value;
      this.form[t] = value;
    },
    //图片上传
    upload(e) {
      if (Array.isArray(e)) {
        this.form.avatarUrl = [...e];
      } else {
        this.form.avatarUrl = [...this.form.avatarUrl, e];
      }
    },

    // 批量导入
    handleImport() {
      this.importShow = true;
    },
    // 导入弹框关闭
    importClose(e) {
      this.importShow = false;
      console.log("e111", e);
      this.onLoad();
    },
    // 添加
    handleCreate() {
      const password = this.findObject(this.formOption.column, "password");
      password.display = true;
      this.form.avatarUrl = [];
      this.isDetails = false;
      this.form.id = "";
      this.dialogVisible = true;
    },
    // 发放徽章
    openForm(type, id) {
      this.$refs.formRef.open(type, id);
    },
    //编辑
    async handleEdit(type, row) {
      // console.log(row)
      this.formType = type;
      const password = this.findObject(this.formOption.column, "password");
      password.display = false;
      this.isDetails = type == "view";
      const { data } = await memberManageView(row.id);
      this.form = { ...data, avatarUrl: [data.avatarUrl || this.userImg] };
      this.form.id = row.id;
      this.dialogVisible = true;
    },
    //切换是否显示
    handleSwitch(e, id) {
      memberManageState({
        id: id,
        state: e,
      }).then((res) => {
        if (res.code === 200) {
          this.$message.success("操作成功");
        }
        this.onLoad();
      });
    },
    //会员分类
    getClassifyList() {
      manageFactoryList({}).then((res) => {
        this.showLoading = false;
        if (res.code === 200) {
          this.classifyList = res.data;
        }
      });
    },
    /** 重置密码 */
    async handleResetPwd(row) {
      try {
        // 重置的二次确认
        const result = await this.$prompt(
          "请输入" + row.username + "的新密码",
          "温馨提示",
          {
            type: "warning",
            inputPattern: /^.{6,20}$/,
            inputErrorMessage: "密码长度 6-20位",
          }
        );

        const password = result.value;
        // 发起重置
        await memberManagePassword({ id: row.id, password });
        this.$message.success("修改成功，新密码是：" + password);
        this.onLoad();
      } catch {}
    },
  },
};
</script>

<style scoped></style>
