<template>
  <el-dialog
    :visible.sync="dialogVisible"
    :title="dialogTitle"
    width="40%"
    :before-close="handleClose"
  >
    <el-form
      ref="formRef"
      v-loading="formLoading"
      :model="formData"
      :rules="formRules"
      label-width="120px"
    >
      <el-form-item label="徽章：" prop="classifyId">
        <el-select
          v-model="formData.classifyId"
          clearable
          multiple=""
          style="width: 400px"
        >
          <el-option
            v-for="item in badgeClassifyList"
            :key="item.id"
            :value="item.id"
            :label="item.name"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button :disabled="formLoading" type="primary" @click="submitForm"
        >确 定</el-button
      >
      <el-button @click="dialogVisible = false">取 消</el-button>
    </template>
  </el-dialog>
</template>
<script>
import FormImgUpload from "@/components/formComponents/formImgUpload";
import { Message } from "element-ui";
import { equityListClassify } from "@/api/equity/manage";
import { t } from "@wangeditor/editor";

export default {
  name: "index",
  components: {
    FormImgUpload,
  },
  props: {
    badgeClassifyList: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      dialogVisible: false, // 弹窗的是否展示
      dialogTitle: "", // 弹窗的标题
      formLoading: false, // 表单的加载中：1）修改时的数据加载；2）提交的按钮禁用
      formType: "", // 表单的类型：create - 新增；update - 修改
      formData: {
        nickname: "",
        mobile: "",
        email: "",
        id: undefined,
        username: "",
        image: [],
        classifyId: [],
      },
      formRules: {
        username: [
          { required: true, message: "门店账号不能为空", trigger: "blur" },
        ],
      },
      classifyList: [],
    };
  },
  created() {
    this.getClassifyList();
  },
  methods: {
    // const formRef = ref() // 表单 Ref
    /** 打开弹窗 */
    async open(type, id) {
      this.dialogVisible = true;
      this.dialogTitle =
        type === "view" ? "查看" : type === "create" ? "新增" : "修改";
      this.formType = type;
      this.resetForm();
      // 修改时，设置数据
      if (id) {
        this.formLoading = true;
        try {
          // this.formData = await UserApi.getUser(id);
        } finally {
          this.formLoading = false;
        }
      }
    },
    async submitForm() {
      // 校验表单
      if (!this.formRef) return;
      const valid = await this.formRef.value.validate();
      if (!valid) return;
      // 提交请求
      formLoading.value = true;
      try {
        const data = formData.value;
        if (formType.value === "create") {
          await UserApi.createUser({ ...data, type: 1 });
          Message.success("新增成功");
        } else {
          await UserApi.updateUser(data);
          Message.success("修改成功");
        }
        dialogVisible.value = false;
        // 发送操作成功的事件
        this.$emit("success");
      } finally {
        formLoading.value = false;
      }
    },
    //图片上传
    upload(e) {
      if (Array.isArray(e)) {
        this.formData.image = [...e];
      } else {
        this.formData.image = [...this.form.image, e];
      }
    },
    // 重置
    resetForm() {},
    //权益分类
    getClassifyList() {
      equityListClassify({}).then((res) => {
        this.showLoading = false;
        if (res.code === 200) {
          this.classifyList = res.data;
        }
      });
    },
    handleClose() {
      this.dialogVisible = false;
    },
  },
};
</script>
